<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <p>
            <small style="color: red">*</small> - {{$t('tickets.required')}}
          </p>
          <hr>
          <label class="mt-2 font-weight-bold" for="machine">{{$t('tickets.machine')}}</label>
          <select id="machine" class="form-control" v-model="machine">
            <option :value="undefined" selected>{{$t('tickets.choose_machine')}}</option>
            <option
              v-for="machine in machineList"
              :key="machine.id"
              :value="machine.id"
            >[{{machine.id}}] {{machine.alias}}</option>
          </select>
          <!-- Заголовок - тема тикета -->
          <label class="mt-2 font-weight-bold" for="title">
            {{$t('tickets.subject')}}
            <small style="color: red">*</small>
          </label>
          <input v-model="title" id="title" type="text" class="form-control">
          <!-- Тело тикета -->
          <label class="mt-2 font-weight-bold">
            {{$t('tickets.message')}}
            <small style="color: red">*</small>
          </label>
          <div class="position-relative">
            <Picker style="z-index: 10000" v-show="showEmojiPicker" :data="emojiIndex" set="apple" @select="addEmoji" :defaultSkin=1 :skin=1 :showPreview=false :showSearch=false :showSkinTones=false :infiniteScroll=false :emojiSize=22 />
            <span
              class="emoji-trigger"
              :class="{ 'triggered': showEmojiPicker }"
              @mousedown.prevent="toggleEmojiPicker"
            >
              <svg
                style="width:20px;height:20px"
                viewBox="0 0 24 24"
              >
                <path fill="#888888" d="M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M10,9.5C10,10.3 9.3,11 8.5,11C7.7,11 7,10.3 7,9.5C7,8.7 7.7,8 8.5,8C9.3,8 10,8.7 10,9.5M17,9.5C17,10.3 16.3,11 15.5,11C14.7,11 14,10.3 14,9.5C14,8.7 14.7,8 15.5,8C16.3,8 17,8.7 17,9.5M12,17.23C10.25,17.23 8.71,16.5 7.81,15.42L9.23,14C9.68,14.72 10.75,15.23 12,15.23C13.25,15.23 14.32,14.72 14.77,14L16.19,15.42C15.29,16.5 13.75,17.23 12,17.23Z" />
              </svg>
            </span>
            <textarea
              v-model="message"
              id="message"
              class="form-control"
              rows="10"
              style="width: 100%"
              :placeholder="$t('tickets.detailed_description')"
              ref="textarea"
            ></textarea>
          </div>
          <div class="col d-grid gap-2">
            <!-- Кнопка "Создать тикет" -->
            <button v-if="!loading" class="btn btn-primary mt-3" @click="createTicket()">{{$t('tickets.create_ticket')}}</button>
            <button v-else class="btn btn-primary mt-3" disabled><b-spinner small></b-spinner> {{$t('links.loading')}}...</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/modules/Axios";

import data from "emoji-mart-vue-fast/data/apple.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";

let emojiIndex = new EmojiIndex(data);

export default {
  name: "CreateTicket",
  components: {
    Picker
  },
  data() {
    return {
      // Тема тикета
      title: undefined,
      // Сообщение - описание тикета
      message: "",
      machineList: [],
      machine: undefined,
      emojiIndex: emojiIndex,
      showEmojiPicker: false,
      loading: false
    };
  },
  methods: {
    /**
     * Метод создания тикета
     */
    async createTicket() {
      try {
        this.loading = true
        // Отправляем запрос на сервер
        const response = await axios.post("Ticket.php", {
          mode: "create_ticket",
          title: this.title,
          message: this.message,
          upid: this.machine
        });
        this.$store.commit('tickets/SET', parseInt(response.data));
        // Перекидываем пользователя на страницу списка тикетов
        this.$router.push({ name: "ticket_list" });
      } catch (error) {
        console.error(error.message);
        this.loading = false
      }
    },
    async getMachineList () {
      // Отправляем запрос на сервер
      try {
        // Отправляем запрос на сервер
        const response = await axios.post('Userproducts.php')

        // Если ответ пуст - завершаем выполнение метода
        if (typeof response.data === 'undefined' || !response.data) return

        response.data.forEach(machine => this.machineList.push(machine))
      } catch (error) {
        console.error(error.message)
      }
    },
    addEmoji(emoji) {
      let message
      if (emoji.skin) {
        let colons = emoji.colons.split(':')
        message = ':' + colons[1] + ':'
      } else {
        message = emoji.colons
      }
      const textarea = this.$refs.textarea
      const cursorPosition = textarea.selectionEnd
      const start = this.message.substring(0, textarea.selectionStart)
      const end = this.message.substring(textarea.selectionStart)
      this.message = start + message + end
      textarea.focus()
      this.$nextTick(() => {
        textarea.selectionEnd = cursorPosition + message.length
      })
      this.showEmojiPicker = false
    },
    toggleEmojiPicker () {
      this.showEmojiPicker = !this.showEmojiPicker
    },
  },
  mounted() {
    this.getMachineList();
  }
};
</script>

<style scoped>
  .emoji-mart {
    position: absolute;
    top: 33px;
    right: 10px;
    height: 180px;
  }
  .emoji-trigger {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 20px;
  }
  .emoji-trigger path {
    transition: 0.1s all;
  }
  .emoji-trigger:hover path {
    fill: #000000;
  }
  .emoji-trigger.triggered path {
    fill: darken(#FEC84A, 15%);
  }
</style>

